<template>
    <b-modal id="room-type-edit-modal" title="Room Type Edit Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 v-if="allowEdit" class="m-0">Edit Room Type</h2>
        <h2 v-else class="m-0">View Room Type</h2>
      </template>
      <validation-observer ref="roomTypeEditFormValidation">
        <b-form @submit.prevent>
            <b-row>
                <b-col>
                <b-form-group label="Name" label-for="name">
                    <template #label>
                    Name <span class="text-danger">*</span>
                    </template>
                    <validation-provider #default="{ errors }" name="Name"
                        :rules="{ required, regex: /^[0-9A-Z-()#&''\s]*$/i }">
                        <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                        placeholder="Name" :disabled="!allowEdit"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-2">
              <template v-if="allowEdit">
                  <b-col md="10" sm="10" lg="10" xl="10">
                  Facilities<span class="text-danger">*</span>
                  <v-select inputId="id" label="name" v-model="selectedFacilities"
                      :options="facilities" multiple autoscroll :disabled="!allowEdit"></v-select>
                  </b-col>
                  <b-col md="2" sm="2" lg="2" xl="2">
                  <b-button size="md" variant="primary" class="mt-2 text-nowrap" @click="selectAllFacilities">
                      Select All
                  </b-button>
                  </b-col>
              </template>
              <template v-else>
                  <b-col md="12" sm="12" lg="12" xl="12">
                  Facilities<span class="text-danger">*</span>
                  <v-select inputId="id" label="name" v-model="selectedFacilities"
                      :options="facilities" multiple autoscroll :disabled="!allowEdit"></v-select>
                  </b-col>
              </template>
            </b-row>
              <template v-if="allowEdit">
                <b-form-group class="text-right">
                    <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                        Update
                    </b-button>
                </b-form-group>
              </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal", "roomType", "allowEdit"],
    data() {
      return {
        name: "",
        required,
        selectedFacilities: null,
        facilities: [],
      };
    },
    async mounted() {
      if (this.showModal) {
        try {
          let res = await this.getFacilitiesUnpaginated();
          this.facilities = res.data
          this.name = this.roomType.name
          this.selectedFacilities = this.roomType.facilities
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        updateRoomType: "appData/updateRoomType",
        getFacilitiesUnpaginated: "appData/getFacilitiesUnpaginated"
      }),
      async validationForm() {
        const success = await this.$refs.roomTypeEditFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          let facility_ids = []
          for(let i=0; i<this.selectedFacilities.length; i++){
            facility_ids.push(this.selectedFacilities[i].id)
          }
          let formData = new FormData();
          let dataToInsert = {
            name: this.name,
            facilities_id: facility_ids,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          formData.append("data", JSON.stringify(dataToInsert));
  

          const res = await this.updateRoomType({
            payload: formData,
            pk: this.roomType.id,
          });
          if (res.status === 200) {
            this.$swal({
              title: "Room Type updated successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("room-type-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },

      selectAllFacilities() {
        this.selectedFacilities = this.facilities
      },

      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  </style>
  